.app-gifcard {
    height: 112px;
    border-radius: 4px;
    overflow-x: hidden;
    margin: 10px auto;
    box-shadow: 0px 4px 4px #00000050;
}

.app-gifcard-title {
    position: absolute;
    left: 0%;
    bottom: -1%;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    color: #161513;
    background: #ffffff;
    padding: 0px 10px;
    border-radius: 0px 4px 0px 4px;
}

.app-profile {
    position: absolute;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    margin: 0px 10px;
}

.app-profile-header {
    display: grid;
    grid-template-columns: 1fr 11fr;
    margin: 0px auto 16px;
    width: 100%;
    height: fit-content;
    vertical-align: super;
}

.app-profile-header-icon img {
    display: block;
    text-align: center;
    margin: 4px auto;
    width: 16px;
    height: 16px;
    color: #ffffff;
}

.app-profile-header-label {
    display: block;
    margin: auto;
    padding-right: 8.5%;
    text-align: center;
    width: 100%;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
}

.app-profile-container {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
}

.app-profile-account {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    width: fit-content;
    display: block;
    margin: auto;
    text-align: center;
    border-radius: 50%;
    background: #00000025;
}

.app-profile-account>img {
    border-radius: 50%;
    filter: drop-shadow(0px 0px 1px rgb(0, 0, 0, 0.25));
}

.app-profile-account-icon {
    position: absolute;
    right: 5%;
    bottom: 5%;
    display: block;
    border: 0.5px solid #00000025;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: #000000;
}

.app-profile-account-icon img {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
}

.app-profile-form {
    display: grid;
    grid-template-columns: 12fr;
    grid-auto-rows: auto;
    margin: 8px auto;
    padding: 0%;
}

.app-form-title {
    display: block;
    margin: 10px auto;
    text-align: center;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #000000;
}

.app-form-divider {
    position: relative;
    top: 0%;
    right: 0%;
    left: 0%;
    bottom: 0%;
    display: block;
    text-align: center;
    margin: auto;
    height: fit-content;
    width: 100%;
}

.app-form-divider span {
    display: block;
    margin: 12px auto;
    text-align: center;
    background: #00000025;
    width: 100%;
    height: 1px;
}

.app-form-group {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-auto-rows: auto;
    width: 100%;
    grid-gap: 10px;
}

.app-form-floating {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: 5px auto;
    width: 100%;
    height: 33px;
}

.app-form-floating .app-form-label {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: left;
}

.app-form-floating .app-form-control {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: left;
    padding: 8px 10px 0px;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #161513;
    border: 0.5px solid #00000025;
    border-radius: 4px;
}

.app-form-floating .app-form-select {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: left;
    padding: 8px 10px 0px 6px;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #161513;
    border: 0.5px solid #00000025;
    border-radius: 4px;
}

.app-form-floating .app-form-control::placeholder,
.app-form-floating .app-form-select::placeholder {
    opacity: 0;
}

.app-form-floating input.app-form-control:focus-visible {
    outline: unset;
    border-radius: 4px;
}

.app-form-floating .app-form-label span {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 10px;
    display: block;
    margin: auto;
    text-align: left;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 13px;
    color: #929191;
    transform: translateY(75%);
    font-size: 0.825em;
    transition-duration: 300ms;
}

.app-form-label:focus-within>span,
.app-form-select:not(:placeholder-shown)+span,
.app-form-control:not(:placeholder-shown)+span {
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 8px;
    line-height: 10px;
    padding: 0px 11.5px;
    color: #5e5e5c;
    transform: translateY(2px);
}

.app-form-btn,
.app-form-btn-outline {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 34px;
}

.app-form-btn-outline button {
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: "Futura PT Demi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #161513;
    background: unset;
    border: 0.5px solid #161513;
    border-radius: 2px;
    padding: 10px 0px;
}

.app-form-btn button {
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: "Futura PT Demi";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    background: #161513;
    border: 0.5px solid #161513;
    border-radius: 2px;
    padding: 10px 0px;
}

.app-home-welcome {
    position: relative;
    top: auto;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
}

.app-home-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 6px 0px;
    width: auto;
    height: 200px;
    margin-bottom: 10px;
}

.app-home-carousel {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    height: 96px;
    background: gray;
    display: block;
    margin-bottom: 10px;
    text-align: center;
}

.app-home-store-title {
    position: relative;
    top: auto;
    left: 4.16%;
    right: 0%;
    bottom: 25%;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color: #000000;
}

.app-home-store {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    margin-bottom: 15px;
    min-height: 148px;
    display: block;
    margin: auto;
    text-align: center;
}

.app-home-benefits {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    margin-bottom: 15px;
    display: block;
    margin: auto;
    text-align: center;
    height: auto;
}

.app-home-share {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    margin-bottom: 15px;
    display: block;
    margin: auto;
    text-align: center;
    height: auto;
}

.app-home-gifcard {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    margin-bottom: 15px;
    display: block;
    margin: 10px;
    text-align: center;
    height: auto;
}

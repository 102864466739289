.app-store-carousel {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin: auto 10px;
    height: 175px;
}

.app-store-category {
    width: 133px;
    height: 148px;
    overflow-y: hidden;
}

.app-store-imagen {
    width: 133px;
    height: 148px;
    overflow-y: hidden;
    z-index: -1;
    border-image: unset;
}

.app-store-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 9px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    padding: 1px 6px;
    gap: 10px;
    position: relative;
    width: 60px;
    height: 14px;
    left: 0px;
    top: 148px;
    background: #ffffff;
}
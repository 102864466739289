body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Futura PT Medium";
    src: url("./app/assets/fonts/futura\ medium\ bt.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Futura PT Demi";
    src: url("./app/assets/fonts/FuturaBQ-DemiBold.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Futura PT Medium Italic";
    src: url("./app/assets/fonts/Futura\ Medium\ Italic\ font.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Futura PT Medium Condensed";
    src: url("./app/assets/fonts/futura\ medium\ condensed\ bt.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Futura PT Bold";
    src: url("./app/assets/fonts/Futura\ Bold\ font.ttf") format("truetype");
}

@font-face {
    font-family: "Futura PT Heavy";
    src: url("./app/assets/fonts/Futura\ Heavy\ font.ttf") format("truetype");
}

@font-face {
    font-family: "Futura PT Book";
    src: url("./app/assets/fonts/Futura\ Book\ font.ttf") format("truetype");
}

@font-face {
    font-family: "Futura PT Light";
    src: url("./app/assets/fonts/Futura\ Light\ font.ttf") format("truetype");
}
.app-dialog {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #00000050;
    overflow: hidden;
}

.app-dialog-container {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 0%;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    transition: 0.5s;
    transition-delay: 250ms;
}

.app-dialog-btn-close {
    position: relative;
    top: 0;
    left: 0%;
    right: 0%;
    display: block;
    margin: auto;
    text-align: center;
    height: 10px;
    width: 100%;
    transition: 0.5s;
    transition-delay: 250ms;
}

.app-dialog-btn-close button {
    border-radius: 4px;
    width: 21px;
    height: 3px;
    border: hidden;
    background: #d1d1d1;
    display: block;
    text-align: center;
    margin: 4px auto;
}

.app-dialog-form {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: 10px auto;
    text-align: center;
    width: 90%;
    transition: 0.5s;
    transition-delay: 250ms;
}
.app-cards {
    display: block;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    width: 220px;
    height: 125px;
}

.app-cards-container-front {
    border-radius: 4px;
    background: linear-gradient( 180deg, rgba(22, 21, 19, 0.2) 0%, rgb(22, 21, 19, 0.75) 100%);
    box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.5), inset 0px -3px 0px -0.5px rgba(0, 0, 0, 0.27);
    filter: blur(0.3px);
    display: block;
    margin: auto;
    height: 120px;
    padding: 7.5px 15px 0px 15px;
    text-align: center;
}

.app-cards-container-back {
    border-radius: 4px;
    background: #f5f5f5;
    box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.5), inset 0px -3px 0px -0.5px rgba(0, 0, 0, 0.27);
    filter: blur(0.3px);
    display: block;
    margin: auto;
    text-align: center;
}

.app-cards-img {
    display: block;
    text-align: center;
    margin: auto;
    width: auto;
    height: 109px;
    background-size: cover;
}

.app-cards-qr {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 36px;
    height: 27px;
    background: #ffffff;
    border-radius: 0px 4px 0px 4px;
}

.app-cards-qr-container {
    display: grid;
    grid-template-columns: 120px 120px;
    gap: 10px;
}

.app-cards-qr-img {
    display: block;
    text-align: center;
    margin: 10px;
    background: #c9c9c9;
    border-radius: 4px;
}

.app-card-qr-content {
    position: absolute;
    top: 20%;
    left: 47.5%;
    right: -5%;
    display: block;
    text-align: center;
    margin: 10px;
}

.app-card-qr-content span {
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #161513;
}

.app-card-qr-content span:last-child {
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: unset;
    color: #5e5e5c;
}

.app-card-back {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 36px;
    height: 27px;
    background: #000000;
    border-radius: 0px 5px 0px 5px;
}

.app-cards-qr button,
.app-cards-back button {
    border: hidden;
    background: unset;
}

.app-cards-content {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 18%;
    bottom: 15%;
    z-index: 1;
    display: block;
    text-align: center;
    margin: auto;
    grid-template-columns: 50px;
    width: 100px;
}

.app-cards-count {
    display: block;
    margin: auto;
    text-align: center;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 64px;
    line-height: 47px;
    text-align: center;
    color: #ffffff;
}

.app-cards-bar {
    display: block;
    margin: 4px auto;
    text-align: center;
    border-bottom: 1px solid #ffffff;
    width: 52px;
}

.app-cards-title {
    display: block;
    text-align: center;
    margin: auto;
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
}
.app-scanner {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    background: #c2c2c2c2;
    line-height: 0;
    box-shadow: inset 5px 5px 5px #00000025, inset -5px -5px 5px #00000025;
}

.app-video {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    width: calc(100% * 0.85);
    height: fit-content;
    border: 1px solid #00000025;
    border-radius: 4px;
}

.app-scanner-actions {
    position: absolute;
    display: inline-block;
    margin: 60px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    height: 6%;
    align-content: space-around;
}

.app-scanner-result {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 10%;
    display: block;
    margin: auto;
    text-align: center;
    width: 80%;
    height: fit-content;
    padding: 5px;
    background: #c2c2c2ff;
    border: 0.5px solid #00000005;
    border-radius: 4px;
}

.app-scanner-result a {
    display: block;
    margin: 10px auto;
    font-family: "Futura PT Demi";
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    color: #00000075;
}

.app-scanner-btn {
    width: 100%;
    height: 100%;
    border: 0.5px solid #00000050;
    border-radius: 4px;
    font-family: "Futura PT Demi";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.05rem;
    box-shadow: inset 1.5px 1.5px #c2c2c225, 1.5px 1.5px 5px #00000050, -1.5px -1.5px 5px #ffffff;
    z-index: 0;
    margin: 1px;
}
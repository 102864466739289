.app-benefits {
    border-top: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    padding: 10px;
    margin: auto 10px !important;
}

.app-benefits-header {
    display: grid;
    grid-template-columns: 10fr 2fr;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #161513;
    z-index: -1;
}

.app-benefits-header i {
    display: block;
    margin: auto;
    text-align: right;
    font-weight: bolder;
    font-size: 1.25rem;
}

.app-benefits-header button {
    border: hidden;
    background: unset;
}

.app-benefits-carousel {
    display: flex;
    gap: 10px;
    width: auto;
    height: auto;
    padding-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 15px auto;
}

.app-benefits-level {
    display: block;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    width: 146px;
    height: 162px;
    padding: 10px;
    border: 0.5px solid #d1d1d1;
    box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
}

.app-benefits-level-header {
    display: grid;
    grid-template-columns: 10fr 2fr;
    margin: auto;
}

.app-benefits-title {
    display: flex;
    float: left;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.app-benefits-status img {
    display: flex;
    float: right;
    width: 16px;
    height: 16px;
}

.app-benefits-status span {
    display: flex;
    float: right;
    width: 16px;
    height: 16px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    border-right: 1px dashed #d1d1d1;
    border-bottom: 1px dashed #d1d1d1;
    rotate: -45deg;
}

.app-benefits-bar {
    display: block;
    text-align: center;
    margin: 5px auto;
    width: 100%;
    height: 6px;
    background: #E8E8E8;
    border-radius: 2px;
}

.app-benefits-bar span {
    display: block;
    width: 0%;
    height: 6px;
    border-radius: 2px;
    background: linear-gradient(90deg, #049254 26.96%, #161513 78.7%);
    transition: 0.3s;
    transition-delay: 250ms;
}

.app-benefits-bar span.fill {
    display: block;
    width: 0%;
    height: 6px;
    border-radius: 2px;
    background: linear-gradient(90deg, #049153 0%, #00CE75 100%);
    transition: 0.3s;
    transition-delay: 250ms;
}

.app-benefists-list li {
    text-align: justify;
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    color: #000000;
    margin: 1px 5px 0px 15px;
}

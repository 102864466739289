.app-achievements {
  display: block;
  text-align: center;
  margin: auto 10px;
  width: auto;
  height: auto;
}

.app-achievements-title {
  display: block;
  margin: 0px auto 10px;
  text-align: center;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
}

.app-achievements-level {
  position: relative;
  display: block;
  margin: 15px auto 0px;
  text-align: center;
}

.app-achievements-level-title {
  display: block;
  margin: auto;
  text-align: left;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

.app-achievements-level-bar {
  position: relative;
  top: 0%;
  left: 0%;
  right: 0%;
  background: #000000;
  display: block;
  margin: 5px auto;
  text-align: center;
}

.app-achievements-level-bar span:nth-child(1) {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  display: block;
  width: auto;
  height: 6px;
  background: #d9d9d9;
}

.app-achievements-level-bar span:nth-child(2) {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  display: block;
  width: 80%;
  height: 6px;
  background: linear-gradient(90deg, #049254 26.96%, #161513 78.7%);
}

.app-achievements-list {
  position: absolute;
  top: 25%;
  left: 0%;
  right: 0%;
  margin: auto 10px;
  display: grid;
  grid-template-columns: 3.33fr 3.33fr 3.33fr;
  grid-auto-rows: auto;
}

.app-achievements-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  text-align: center;
  margin: 0px auto 15px;
  font-family: "Futura PT Book";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #5e5e5c;
}

.app-achievements-item img {
  width: 64px;
  height: 64px;
}

.app-achievements-item img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.app-wallet {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-wallet-header {
  position: absolute;
  top: -1%;
  left: 0%;
  right: 0%;
}

.app-wallet-header img {
  position: relative;
  top: 15%;
  left: 60%;
  font-family: "Futura PT Medium";
  font-weight: bold;
  display: block;
  margin: 2.5px auto;
  text-align: center;
  z-index: 0 !important;
}

.app-wallet-header div:last-child {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  display: block;
  margin: 5px auto;
  text-align: center;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
  z-index: -1;
}

.app-wallet-container {
  position: relative;
  top: 5%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  text-align: center;
  margin: auto;
}

.app-wallet-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding: 6px 0px;
  width: auto;
  height: 200px;
}

.app-wallet-information {
  display: block;
  text-align: center;
  margin: auto;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #5e5e5c;
}

.app-wallet-information span {
  display: block;
}

.app-wallet-historial {
  display: block;
  margin: 10px;
  text-align: center;
  width: auto;
  height: auto;
}

.app {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-header {
    position: absolute;
    top: 2%;
    left: 0%;
    right: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 10px;
    gap: 10px;
    width: auto;
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.app-header .icon img {
    display: block;
    margin: auto;
    text-align: center;
}

.app-container {
    position: absolute;
    top: 10%;
    left: 0%;
    right: 0%;
    bottom: 10%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-footer {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    text-align: center;
    margin: auto;
    width: auto;
    height: auto;
}

.app-menu {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #161513;
    overflow: hidden;
    height: calc(100%);
    z-index: 1;
}

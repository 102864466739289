.app-menu-container {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 0%;
    margin: 24px 10px;
}

.app-menu-header {
    display: block;
    margin: 0px auto 39px;
    text-align: center;
    width: auto;
}

.app-menu-items {
    display: block;
    margin: auto;
    text-align: center;
}

.app-menu-link {
    display: block;
    text-decoration: none;
}

.app-menu-link:visited {
    color: #ffffff;
}

.app-menu-item {
    display: grid;
    grid-template-columns: 1fr 11fr;
    margin: 0px auto 16px;
    width: 100%;
    height: fit-content;
    vertical-align: super;
}

.app-menu-item-icon img {
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
    height: fit-content;
    color: #ffffff;
}

.app-menu-item-label {
    display: block;
    margin: auto;
    text-align: left;
    width: 90%;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    height: 16px !important;
}

.app-menu-close {
    position: absolute;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: block;
    text-align: center;
    margin: 42px auto;
    height: 41px;
    width: 41px;
}

.app-menu-close-btn {
    display: block;
    margin: auto;
    text-align: center;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background: unset;
    border: 0.5px solid #ffffff;
    border-radius: 50%;
}

.app-share {
  border-bottom: 1px solid #c2c2c2;
  padding: 10px;
  margin: auto 10px !important;
}

.app-share-header {
  display: grid;
  grid-template-columns: 10fr 2fr;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #161513;
}

.app-share-header i {
  display: block;
  margin: auto;
  text-align: right;
  font-weight: bolder;
  font-size: 1.25rem;
}

.app-share-header button {
  border: hidden;
  background: unset;
}

.app-share-container {
  display: block;
  text-align: center;
  margin: 10px auto 20px;
  width: 100%;
  height: auto;
}

.app-share-title {
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.app-share-qr {
  display: block;
  text-align: center;
  margin: 10px auto 0px auto;
  width: 96px;
  height: 96px;
}

.app-share-qr-code {
  display: block;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 46px;
  max-width: 128px;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.app-share-message {
  display: block;
  text-align: center;
  margin: auto;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.app-share-actions {
  display: flex;
  text-align: center;
  margin: 5px auto 15px;
  width: 200px;
  height: 64px;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #000000;
}

.app-share-actions span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding: 5px ;
}

.app-share-actions a {
  text-decoration: none;
  color: unset;
}

.app-about {
    position: absolute;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    margin: 0px 10px;
}

.app-about-header {
    display: grid;
    grid-template-columns: 1fr 11fr;
    margin: 0px auto 16px;
    width: 100%;
    height: fit-content;
    vertical-align: super;
}

.app-about-header-icon img {
    display: block;
    text-align: center;
    margin: 4px auto;
    width: 16px;
    height: 16px;
    color: #ffffff;
}

.app-about-header-label {
    display: block;
    margin: auto;
    padding-right: 8.5%;
    text-align: center;
    width: 100%;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
}

.app-about-container {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
}

.app-about-account {
    position: relative;
    top: auto;
    left: 0%;
    right: 0%;
    bottom: auto;
    width: fit-content;
    display: block;
    margin: auto;
    text-align: center;
    border-radius: 50%;
    background: #00000025;
}

.app-about-account>img {
    border-radius: 50%;
    filter: drop-shadow(0px 0px 1px rgb(0, 0, 0, 0.25));
}

.app-about-account-icon {
    position: absolute;
    right: 0%;
    bottom: 0%;
    display: block;
    border: 0.5px solid #00000025;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: #000000;
}

.app-about-account-icon img {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
}

.app-about-container-icon img {
    display: block;
    margin: 10px auto;
    text-align: center;
    width: 29px;
    height: 16px;
}

.app-about-paragraph {
    display: block;
    margin: 0px auto 10px;
    text-align: justify;
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
}

.app-about-social-title {
    display: block;
    margin: 20px auto 20px;
    text-align: center;
    font-family: "Futura PT Medium";
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    width: fit-content;
}

.app-about-social-items {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: grid;
    margin: 20px auto;
    grid-template-columns: 3fr 3fr 3fr;
    grid-auto-rows: auto;
    gap: 16px;
    width: fit-content;
}
.app-verify {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
}

.app-verify-header {
  position: relative;
  top: 2.55%;
  left: 0%;
  right: 0%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 0px;
}

.app-verify-container {
  position: absolute;
  top: 28.66%;
  left: 6.6%;
  right: 6.6%;
  bottom: 34.66%;
}

.app-verify-label {
  display: block;
  margin: auto;
  text-align: center;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #263238;
  padding-bottom: 0.88%;
}

.app-verify-label span {
  display: block;
  margin: auto;
}

.app-verify-info {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 9px;
  line-height: 12.88px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #263238;
}

.app-verify .input-group {
  margin: 7.5% auto !important;
}

.app-verify .input-group {
  margin: 5px;
  gap: 10px;
}

.app-verify .input-group .form-control {
  display: block;
  text-align: center;
  margin: auto;
  border-radius: 2px !important;
  border: 0.5px solid #161513;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  width: 45px;
  height: 33px;
  color: #161513;
}

.app-verify-button {
  display: block;
  margin: 4% auto;
  text-align: center;
  width: 100%;
  height: 34px;
  background: #161513;
  border: 0.5px solid #161513;
  border-radius: 2px;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.app-verify-button:disabled {
  background: #f5f5f5;
  border: 0.5px solid #929191;
  color: #929191;
}

.app-resend-button {
  display: block;
  margin: 4% auto;
  text-align: center;
  width: 100%;
  height: 34px;
  background: #ffffff;
  border: 0.5px solid #161513;
  border-radius: 2px;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.app-resend-button:hover {
  background: #161513;
  border: 0.5px solid #ffffff;
  color: #ffffff;
}

.app-resend-button:disabled {
  background: #f5f5f5;
  border: 0.5px solid #929191;
  color: #929191;
}

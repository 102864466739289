.app-carousel {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    height: auto;
    overflow: hidden;
}

.app-carousel-container {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    z-index: 0;
}

.app-carousel-body {
    position: absolute;
    top: 25%;
    left: 0%;
    right: 0%;
    width: 100%;
    height: auto;
    z-index: 1;
    display: block;
    text-align: center;
}

.app-carousel-title {
    display: block;
    margin: auto;
    height: auto;
    text-align: center;
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.59px;
    letter-spacing: 0.1em;
    color: #ffffff;
}

.app-carousel-indicators {
    position: absolute !important;
    bottom: 7.5% !important;
    left: 0%;
    right: 0%;
    display: block;
    margin: auto;
    text-align: center;
    align-content: space-between;
    z-index: 1;
}

.app-carousel-indicators button {
    margin: auto 5px;
    padding: 0px;
    gap: 10px;
    width: 4px;
    height: 4px;
    background: #e8e8e8;
    border-radius: 4px;
    border: hidden;
    flex: none;
    order: 1;
    flex-grow: 0;
    transition: 0.35s;
    transition-delay: 0.25ms;
}

.app-carousel-indicators button.active {
    padding: 0px;
    gap: 10px;
    width: 16px;
    height: 4px;
    background: #ffffff;
    border-radius: 4px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: 0.35s;
    transition-delay: 0.25ms;
}

.app-card {
  width: 90%;
  height: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.5s, left 0.5s;
}

.app-card-front,
.app-card-back {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc50;
  border-radius: 4px !important;
  transition: transform 0.5s, left 0.5s;
}

.app-card-front {
  transform: rotateY(0deg);
  background: linear-gradient(
    180deg,
    rgba(22, 21, 19, 0.2) 0%,
    rgb(22, 21, 19, 0.75) 100%
  );
  box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.5),
    inset 0px -3px 0px -0.5px rgba(0, 0, 0, 0.27);
}

.app-card-back {
  transform: rotateX(180deg);
  background: #fff !important;
  border-radius: 4px;
  box-shadow: 0px 8px 5px -5px rgba(0, 0, 0, 0.5),
    inset 0px -3px 0px -0.5px rgba(0, 0, 0, 0.27);
  filter: blur(0.3px);
}

.app-card.flipped {
  left: 50%;
  transform: rotateX(180deg);
}

.app-card-btn {
  position: absolute;
  right: 0%;
  top: 0%;
  width: 36px;
  height: 28px;
  display: block;
  margin: auto;
  text-align: right;
  padding-right: 10px;
  background: #fff;
  border-radius: 0px 4px 0px 4px;
}

.app-card-qr {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.app-card-qr-code {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.app-card-qr-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  gap: 10px;
}

.app-card-qr-text span:nth-child(1) {
  font-weight: bold;
}

.app-card-img {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background-image: url("../../../assets/images/home/cards/card-image.webp");
  filter: contrast(0.8);
  background-size: cover;
  border-radius: 4px;
  z-index: -1;
}

.app-card-count {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 64px;
  line-height: 47px;
  text-align: center;
  color: #ffffff;
}

.app-card-bar {
  display: block;
  margin: 4px auto;
  text-align: center;
  border-bottom: 1px solid #ffffff;
  width: 52px;
}

.app-card-title {
  display: block;
  text-align: center;
  margin: auto;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

.app-card-footer-title {
  position: absolute;
  left: 2.5%;
  bottom: 7.5%;
  font-family: "Futura PT Light";
  font-style: normal;
  font-size: 12px;
  font-weight: 450;
  line-height: 12px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.app-card-icon {
  position: absolute;
  right: 2.5%;
  bottom: 4.5%;
  font-size: 12px;
}

.app-historial-title {
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
}

.form-floating {
  display: block;
  margin: 10px auto;
  text-align: center;
  width: auto;
  height: auto;
}

.form-floating label {
  padding: 0% auto;
  margin: 0%;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #5e5e5c;
  z-index: 0 !important;
}

.form-select {
  border-radius: 2px;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #161513;
  padding: 0% auto;
  margin: auto;
}

.app-historial-date {
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 11px;
  display: flex;
  align-items: center;
  color: #5e5e5c;
  margin-top: 10px;
}

.app-historial-list {
  display: block;
  text-align: center;
  margin: 5px auto;
  width: 100%;
  padding: 0%;
}

.app-historial-list li:first-child {
  border-top: 0.5px solid #d1d1d1;
}

.app-historial-list li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 43px;
  width: auto;
  height: 42px;
  border-bottom: 0.5px solid #d1d1d1;
  margin: -0.5px 0px;
}

.app-historial-list-item div:first-child span {
  display: block;
  text-align: left;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  align-items: center;
  /* Dark Content/Primary */
  color: #161513;
}

.app-historial-list-item div:first-child span {
  display: block;
  text-align: left;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  align-items: center;
  /* Dark Content/Primary */
  color: #161513;
}

.app-historial-list-item div:last-child span.add {
  display: block;
  margin: auto;
  text-align: right;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  align-items: center;
  color: #02ae64;
}

.app-historial-list-item div:last-child span.del {
  display: block;
  margin: auto;
  text-align: right;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  color: #5e5e5c;
}



.app-footer-container {
    display: flex;
    justify-content: space-between;
}

.app-footer-icon {
    display: block;
    text-align: center;
    padding: 10px auto 0px;
    margin: 25px auto 5px;
    width: 36px;
    height: 36px;
}

.app-footer-icon img {
    display: block;
    margin: auto;
    text-align: center;
}

.app-footer-icon span {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 5px;
    font-family: "Futura PT Book";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    color: #161513;
}

.app-footer-icon .fill {
    display: none;
}

.app-footer-icon:has(a.active) .outline {
    display: none;
}

.app-footer-icon:has(a.active) .fill {
    display: block;
}

.app-login {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  display: block;
  text-align: center;
  overflow: hidden;
}

.app-login-header {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  display: block;
  margin: auto;
  text-align: center;
  z-index: 1;
}

.app-login-container {
  display: block;
  margin: auto;
  position: absolute;
  bottom: 0%;
  right: 0%;
  top: -2.27%;
  left: -1.11%;
  background-image: url("../../assets/images/onboarding4.webp");
  background-size: cover;
  filter: blur(0.6px);
  z-index: 0;
}

.app-login-shadow {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.69) 65.1%
  );
}

.app-login-body {
  display: block;
  margin: auto;
  position: absolute;
  top: 34%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  filter: none !important;
}

.app-login-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #c2c2c2;
  padding: 10px;
  margin-bottom: 18px;
}

.app-login-options .nav-link.active {
  color: #f5f5f5;
  text-shadow: 0px 0px 12px #000000;
}

.app-login-options .col {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.app-login-options .vdivider {
  display: flex;
  max-width: 15px;
  height: 15px;
  text-align: center;
  margin: auto;
}

.app-login-options span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1px;
  height: auto;
  background: #e8e8e8;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.app-login-options div:last-child {
  position: relative;
  right: 5%;
}

.app-login-button-google {
  display: block;
  text-align: center;
  margin: auto;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: rgba(54, 54, 54, 0.85);
  flex: none;
  order: 0;
  width: 90%;
  height: auto;
  padding: 7.5px;
  border-radius: 2px;
  border: 1px solid #ffffff;
  flex-grow: 0;
}

.app-login-button-google div,
.app-login-button-facebook div {
  padding: 0%;
}

.app-login-button-google img,
.app-login-button-facebook img {
  display: block;
  text-align: center;
  margin: auto;
}

.app-login-button-facebook {
  display: block;
  text-align: center;
  margin: auto;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 0;
  width: 90%;
  height: auto;
  padding: 7.5px;
  border-radius: 2px;
  border: 1px solid #1878f3;
  background: #1878f3;
  flex-grow: 0;
}

.app-login-button {
  display: block;
  text-align: center;
  margin: auto;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 0;
  width: 90%;
  height: auto;
  padding: 7.5px;
  border-radius: 2px;
  background: unset;
  border: 1px solid #ffffff;
  flex-grow: 0;
}

.form-floating {
  display: block;
  text-align: center;
  margin: 0% auto;
  width: 90%;
  padding: 0%;
}

.form-floating .form-control {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Dark Content / Placeholder */
  color: #161513;
  max-height: 30px;
  border: hidden;
  border-radius: 2px;
}

.form-floating .form-control:empty {
  font-size: 12px;
  line-height: 15px;
  color: #929191;
}

.app-login-form-info {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  color: #ededed;
}

.form-floating label {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #5e5e5c;
}

.app-form-button {
  position: absolute;
  left: 0%;
  right: 0%;
  display: block;
  margin: 5px auto !important;
  text-align: center;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  color: #ffffff;
  height: auto;
  padding: 7.5px;
  width: 90% !important;
  border-radius: 2px;
  background: unset;
  border: 1px solid #ffffff;
}

.app-form-forget-password {
  position: absolute;
  left: 0%;
  bottom: 17.5%;
  right: 0%;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  align-items: center;
  text-align: center;
  color: #ededed;
}

.app-menu-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.app-menu-component-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}

.app-login-error {
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ffffff25;
  border-radius: 4px;
  color: #fff;
  background-color: #ff000075;
}

.app-login-success {
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ffffff25;
  border-radius: 4px;
  color: #fff;
  background-color: #009900;
}

.app-carousel {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    height: 100%;
    overflow: hidden;
}

.app-carousel-header {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    z-index: 1;
    display: block;
    margin: auto;
    text-align: center;
}

.app-carousel-container {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    z-index: 0;
}

.app-carousel-shadow {
    position: absolute;
    background: rgb(0, 0, 0, 0.25);
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
}

.app-carousel-body {
    position: absolute;
    top: 48.66%;
    left: 12.91%;
    right: 11.25%;
    bottom: 17.11%;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.app-carousel-title {
    width: 182px;
    height: 93px;
    text-transform: uppercase;
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30.77px;
    display: flex;
    text-align: left;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    padding-bottom: auto;
}

.app-carousel-subtitle {
    /* Subtitle */
    width: 178px;
    height: 54px;
    font-family: "Futura PT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #e6e6e6;
    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 1;
}

.app-carousel-indicators {
    position: absolute !important;
    bottom: 7.5% !important;
    left: 0%;
    right: 0%;
    display: block;
    margin: auto;
    text-align: center;
    align-content: space-between;
    z-index: 1;
}

.app-carousel-indicators button {
    margin: auto 5px;
    padding: 0px;
    gap: 10px;
    width: 4px;
    height: 4px;
    background: #e8e8e8;
    border-radius: 4px;
    border: hidden;
    flex: none;
    order: 1;
    flex-grow: 0;
    transition: 0.35s;
    transition-delay: 0.250ms;
}

.app-carousel-indicators button.active {
    padding: 0px;
    gap: 10px;
    width: 16px;
    height: 4px;
    background: #ffffff;
    border-radius: 4px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: 0.35s;
    transition-delay: 0.250ms;
}
.app-giftcards {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-giftcards-header {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.app-giftcards-header div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Futura PT Medium";
  font-style: normal;
  letter-spacing: 0.2em;
  color: #000000;
  text-transform: uppercase;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  height: auto;
  margin: 5px auto;
  z-index: 0 !important;
}

.app-giftcards-header div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 35px;
}

.app-giftcards-header div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-right: 50px;
}

.app-giftcards-container {
  position: relative;
  top: 10%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  text-align: center;
  margin: auto 10px;
}

.app-giftcards-list {
  display: block;
  margin: auto;
  text-align: center;
}

.app-giftcards-item {
  position: relative;
  display: block;
  margin: 20px auto;
  text-align: center;
  width: fit-content;
  border-radius: 5px;
  width: auto;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.app-giftcards-item img {
  width: 95%;
  height: auto;
  min-width: 256px;
}

.app-giftcards-card {
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  display: block;
  text-align: center;
  margin: 5% auto;
}

.app-giftcards-card img {
  display: block;
  text-align: center;
  margin: auto;
  width: 100%;
  height: auto;
  background: #ffffff;
}

.app-gifcards-card-name {
  position: absolute;
  left: 5%;
  bottom: 15%;
  display: block;
  margin: auto;
  text-align: left;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 11px;
  line-height: 9px;
  letter-spacing: 0.1em;
  color: #000000;
}

.app-giftcards-container .form-floating .form-select {
  border: unset;
}

.app-giftcards-container .form-floating {
  border: 0.5px solid #d1d1d1;
  border-radius: 2px;
}

.app-gifcards-info {
  display: block;
  text-align: left;
  margin: auto;
  align-items: center;
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  color: #5e5e5c;
}

.app-gifcards-info span {
  margin: auto 5px;
}

.app-gifcards-info span i {
  width: 16px;
  height: 16px;
}

.app-giftcards-container .form-check {
  display: block;
  text-align: center;
  margin: auto;
  border: 0.5px solid #d1d1d1;
  border-radius: 2px;
  padding: 2% 1%;
}

.app-giftcards-container .form-check .form-check-input {
  display: block;
  margin: 5px;
  border: 0.5px solid #5e5e5c;
  border-radius: 2px;
}

.form-check-input:checked {
  color: #ffffff;
  background-color: #000000;
}

.app-giftcards-container .form-check .form-check-label {
  display: block;
  margin: auto;
  text-align: left;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 150%;
  color: #7b8794;
}

.app-giftcards-container .form-check label strong {
  color: #000000;
}

.app-giftcards-button {
  display: block;
  text-align: center;
  margin: 10px auto;
  width: 100%;
  height: 34px;
  background: unset;
  border: 0.5px solid #929191;
  border-radius: 2px;
  font-family: "Futura PT Demi";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #929191;
}

.app-news {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-news-header {
  position: absolute;
  top: -1%;
  left: 0%;
  right: 0%;
}

.app-news-header img {
  position: relative;
  top: 15%;
  left: 60%;
  font-family: "Futura PT Medium";
  font-weight: bold;
  display: block;
  margin: 2.5px auto;
  text-align: center;
  z-index: 0 !important;
}

.app-news-header div:last-child {
  position: relative;
  top: 0%;
  left: -5%;
  right: 0%;
  display: block;
  margin: 5px auto;
  text-align: center;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2em;
  color: #000000;
}

.app-news-container {
  position: relative;
  top: 10%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  text-align: center;
  margin: auto 10px;
}

.app-news-list {
  display: block;
  margin: auto;
  text-align: center;
}

.app-news-item {
  position: relative;
  display: block;
  margin: 10px auto;
  text-align: center;
  width: fit-content;
  width: 90%;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
}

.app-news-item-description {
  position: absolute;
  left: 0%;
  bottom: 0%;
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 9px;
  line-height: 11.54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
  background: #ffffff;
  padding: 1px 4px 1px 4px;
  border-radius: 0px 4px 0px 4px;
}

.app-news-item-description:empty {
  display: none;
}
